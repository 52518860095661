import React, { useContext, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button } from "../../../components/button/button";
import { toast, ToastContainer } from "react-toastify";
import { CURRENCY } from "../../../utils/constant";
import { Scrollbars } from "react-custom-scrollbars";
import { v4 as uuidv4 } from "uuid";
import { generateUniqueId } from "../../../utils/id";
import "../../../../node_modules/react-toastify/dist/ReactToastify.css";
import {
	SpinnerContainer,
	Spinner,
} from "../../user-profile/order/order.style";
import { CardLogo } from "../../../components/payment-card/payment-card.style";
import visa from "../../../assets/images/visaCard.jpg";
import CheckoutWrapper, {
	CheckoutContainer,
	CheckoutInformation,
	InformationBox,
	DeliverySchedule,
	CheckoutSubmit,
	HaveCoupon,
	CouponBoxWrapper,
	CouponInputBox,
	CouponCode,
	RemoveCoupon,
	TermConditionText,
	TermConditionLink,
	CartWrapper,
	CalculationWrapper,
	OrderInfo,
	Title,
	ItemsWrapper,
	Items,
	Quantity,
	Multiplier,
	ItemInfo,
	Price,
	TextWrapper,
	Text,
	Bold,
	Small,
	NoProductMsg,
	NoProductImg,
	ModalOverlay,
	ModalContent,
	CloseButton
} from "./checkout-two.style";

import {
	getAddress,
	getCity,
	getAddressDetail,
	getTentativeDelivery,
	getTentativePickup,
	getWarning,
	getDeliveryMethod,
} from "../../../utils/cartHelper";

import { NoCartBag } from "../../../assets/icons/NoCartBag";
import ReactPixel from 'react-facebook-pixel';

import Sticky from "react-stickynode";
import { ProfileContext } from "../../../contexts/profile/profile.context";
import { FormattedMessage } from "react-intl";
import { useCart } from "../../../contexts/cart/use-cart";
import { useLocale } from "../../../contexts/language/language.provider";
import { useWindowSize } from "../../../utils/useWindowSize";
import Coupon from "../../../features/coupon/coupon";
import Schedules from "../../../features/schedule/schedule";
import Contact from "../../../features/contact/contact";
import Payment from "../../../features/payment/payment";
import Address from "../../../features/address/address";
import axios from "axios";
import { createPlexoSession } from "../../../scripts/Plexo";
import { css } from "glamor";

const containerStyle = css({
	position: "relative",
});

const PICKUP_CARRASCO = 1;
const PICKUP_POCITOS = 3;
const DELIVERY = 2;

const OrderItem = ({ product }) => {
	const { id, quantity, title, name, unit, price, salePrice } = product;
	const displayPrice = salePrice ? salePrice : price;
	return (
		<Items key={id}>
			<Quantity>{quantity}</Quantity>
			<Multiplier>x</Multiplier>
			<ItemInfo>
				{name ? name : title} {unit ? `| ${unit}` : ""}
			</ItemInfo>
			<Price>
				{CURRENCY}
				{displayPrice * quantity}
			</Price>
		</Items>
	);
};
const CheckoutWithSidebar = ({ token, deviceType }) => {
	let history = useHistory();
	const [hasCoupon, setHasCoupon] = useState(false);
	const { state } = useContext(ProfileContext);
	const { isRtl } = useLocale();
	const {
		items,
		removeCoupon,
		coupon,
		clearCart,
		delivery,
		cartItemsCount,
		calculatePrice,
		calculateDiscount,
		calculateSubTotalPrice,
		addTemporalOrder,
		shouldPayDelivery,
		handleDelivery,
	} = useCart();
	const [loading, setLoading] = useState(false);
	const [isValid, setIsValid] = useState(false);
	const { address, contact, id, schedules, name, email } = state;

	const size = useWindowSize();
	const addDeliveryCost = shouldPayDelivery();
	const tentativePickup = getTentativePickup(items);
	const tentativeDelivery = getTentativeDelivery(items);
	const [message, setMessage] = useState('');
	const { limit, showWarning } = getWarning(items, delivery);
	const warningMessage = `Determinados productos llevan un tiempo de producción mayor al previsto (${limit}hrs), su fecha de entrega se ha ajustado.`;

const selectedSchedule = schedules.find((s) => s.type === "primary");

const finalDeliveryMethod = delivery 
  ? DELIVERY 
  : selectedSchedule?.id 
    ? parseInt(selectedSchedule.id) 
    : PICKUP_CARRASCO; // fallback if no selection


	const handleSubmit = async () => {
		try {
			const orderId = generateUniqueId();

			const orderData = {
				orderId,
				amount: calculatePrice(),
				discount: calculateDiscount(),
				products: items,
				subtotal: calculateSubTotalPrice(),
				deliveryTime: delivery ? tentativeDelivery : tentativePickup,
				coupon,
				deliveryFee: delivery ? 260 : 0,
				deliveryMethod: finalDeliveryMethod,
				shouldPayDelivery: addDeliveryCost,
				userId: id,
				date: new Date(),
				deliveryAddress: address?.length > 0 ? getAddress(...address) : "No hay información.",
				email: email || "",
				customerAddress:
					address?.length > 0 ? getAddressDetail(...address) : "",
				customerCity: address?.length > 0 ? getCity(...address) : "",
				customerName: name || "",
				customerPhone: contact && contact.length > 0 ? contact[0].number : "-",
				deviceFingerprint: `visanetuy_px_42213_${orderId}`
			};

			await axios.post('https://nora-rey-backend.herokuapp.com/api/orders/add-pending', orderData);

			const sessionId = await createPlexoSession(orderId);
			if (!sessionId) {
				toast.error("Error de comunicación de pagos.");
				return;
			}
			window.location.href = `https://secure.plexo.com.uy/${sessionId}`;

		} catch (error) {
			console.error('Checkout error:', error);
			toast.error("Error iniciando el pago");
		}
	};

	// const handleSubmit = async () => {
	// 	if (!calculatePrice() || items.length === 0) {
	// 		toast.error("Your cart is empty or price is invalid.");
	// 		return;
	// 	}

	// 	try {
	// 		setLoading(true);
	// 		const orderId = generateUniqueId();

	// 		const orderData = {
	// 			orderId,
	// 			amount: calculatePrice(),
	// 			discount: calculateDiscount(),
	// 			products: items,
	// 			subtotal: calculateSubTotalPrice(),
	// 			deliveryTime: delivery ? tentativeDelivery : tentativePickup,
	// 			coupon,
	// 			deliveryFee: delivery ? 260 : 0,
	// 			deliveryMethod: finalDeliveryMethod,
	// 			shouldPayDelivery: addDeliveryCost,
	// 			userId: id,
	// 			date: new Date(),
	// 			deliveryAddress: address?.length > 0 ? getAddress(...address) : "No hay información.",
	// 			email: email || "",
	// 			customerAddress:
	// 				address?.length > 0 ? getAddressDetail(...address) : "",
	// 			customerCity: address?.length > 0 ? getCity(...address) : "",
	// 			customerName: name || "",
	// 			customerPhone: contact && contact.length > 0 ? contact[0].number : "-",

	// 		};

	// 		// Create pending order
	// 		await axios.post('http://localhost:3001/api/orders/add-pending', orderData);

	// 		// Get Plexo session
	// 		const sessionId = await createPlexoSession(orderId);
	// 		if (!sessionId) {
	// 			toast.error("Error de comunicación de pagos.");
	// 			return;
	// 		}

	// 		// Redirect to Plexo
	// 		window.location.href = `https://web.testing.plexo.com.uy/${sessionId}`;

	// 	} catch (error) {
	// 		console.error('Checkout error:', error);
	// 		toast.error("Error iniciando el pago");
	// 		setLoading(false);
	// 	}
	// };

	useEffect(() => {
		if (
			calculatePrice() > 0 &&
			cartItemsCount > 0 &&
			address.length > 0 &&
			contact.length &&
			schedules.length
		) {
			setIsValid(true);
		} else {
			setIsValid(false);
		}
	}, [state]);

	useEffect(() => {
		handleDelivery(false);
		return () => {
			handleDelivery(false);
		};
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(
					`https://nora-rey-backend.herokuapp.com/api/holidays/`
				);
				if (response.data?.holidaysArray?.length) {
					const { holidaysArray } = response.data;
					const currentDate = holidaysArray[0];
					const start = new Date(currentDate.start);
					const end = new Date(currentDate.end);
					const message = currentDate.message;
					const today = new Date();
					var diffDaysStart = parseInt((start - today) / (1000 * 60 * 60 * 24), 10);

					if (diffDaysStart < 2 || (today >= start && today < end)) {
						setMessage(message)
					}
				}
			} catch (error) {
				console.error('Error fetching holidays:', error);
			}
		};

		fetchData();
	}, []);

	const newSchedule = [
		{
			id: "1",
			type: "primary",
			title: "Pick up en Carrasco",
			subtitle: "Schroeder 6464",
			time_slot: items?.length ? `${tentativePickup}: 10.00 AM - 8.00 PM` : "No hay información",
			disabled: false,
		},
		{
			id: "3",
			type: "secondary",
			title: "Pick up en Pocitos",
			subtitle: "26 de Marzo 1153",
			time_slot: items?.length ? `${tentativePickup}: 10.00 AM - 8.00 PM` : "No hay información",
			disabled: false,
		},
		{
			id: "2",
			type: "secondary",
			title: "Delivery",
			time_slot: items?.length ? `${tentativeDelivery}: 10.00 AM - 3.00 PM` : "No hay información",
			disabled: false,
		},
	];

	const closedDatesMessage = (
		<div>
			<h5>Atención</h5>
			<p>{message}</p>
			<p style={{ fontStyle: 'italic', marginTop: 5 }}>El equipo de Nora Rey.</p>
		</div>
	);

	const shouldClose = !!message;

	return (
		<form>
			<ToastContainer className={containerStyle} />

			<form id="commerce_form">
				<input id="PWToken" name="PWToken" type="hidden" />
			</form>
			<CheckoutWrapper>
				<CheckoutContainer>
					{loading ? (
						<SpinnerContainer>
							<Spinner></Spinner>
						</SpinnerContainer>
					) : (
						<CheckoutInformation>
							{/* DeliverySchedule */}
							<InformationBox>
								<DeliverySchedule>
									{shouldClose ? closedDatesMessage : (
										<Schedules
											increment={true}
											setDelivery={(hasDelivery) => handleDelivery(hasDelivery)}
											newSchedule={newSchedule}
											warningMessage={showWarning ? warningMessage : ""}
										/>
									)}

								</DeliverySchedule>
							</InformationBox>

							{/* DeliveryAddress */}
							{
								<InformationBox>
									<Address
										increment={true}
										flexStart={true}
										isDelivery={delivery}
										buttonProps={{
											variant: "text",
											type: "button",
											className: "addButton",
										}}
										icon={true}
									/>
								</InformationBox>
							}

							{/* Contact number */}
							<InformationBox>
								<Contact
									increment={true}
									flexStart={true}
									buttonProps={{
										variant: "text",
										type: "button",
										className: "addButton",
									}}
									icon={true}
								/>
							</InformationBox>
							{/* PaymentOption */}

							<InformationBox
								className="paymentBox"
								style={{ paddingBottom: 30 }}
							>
								<Payment deviceType={deviceType} increment={true} />

								{/* Coupon start */}
								{coupon ? (
									<CouponBoxWrapper>
										<CouponCode>
											<FormattedMessage id="couponApplied" />
											<span>{coupon.code}</span>

											<RemoveCoupon
												onClick={(e) => {
													e.preventDefault();
													removeCoupon();
													setHasCoupon(false);
												}}
											>
												<FormattedMessage id="removeCoupon" />
											</RemoveCoupon>
										</CouponCode>
									</CouponBoxWrapper>
								) : (
									<CouponBoxWrapper>
										{!hasCoupon ? (
											<HaveCoupon onClick={() => setHasCoupon((prev) => !prev)}>
												<FormattedMessage
													id="specialCode"
													defaultMessage="Have a special code?"
												/>
											</HaveCoupon>
										) : (
											<>
												<CouponInputBox>
													<Coupon
														errorMsgFixed={true}
														className="normalCoupon"
													/>
												</CouponInputBox>
											</>
										)}
									</CouponBoxWrapper>
								)}

								<TermConditionText>
									<FormattedMessage
										id="termAndConditionHelper"
										defaultMessage="By making this purchase you agree to our"
									/>
									<Link href="#">
										<TermConditionLink>
											<FormattedMessage
												id="termAndCondition"
												defaultMessage="terms and conditions."
											/>
										</TermConditionLink>
									</Link>
								</TermConditionText>

								{/* CheckoutSubmit */}
								<CheckoutSubmit>
									<Button
										type="button"
										onClick={handleSubmit}
										disabled={!isValid}
										size="big"
										loading={loading}
										style={{ width: "100%" }}
									>
										<FormattedMessage
											id="processCheckout"
											defaultMessage="Proceed to Checkout"
										/>
									</Button>
								</CheckoutSubmit>

								<TextWrapper>
									<Text>
										<Small>
											<FormattedMessage
												id="emailConfirmationText"
												defaultMessage="Te llegará la confirmación por correo electrónico."
											/>{" "}
										</Small>
									</Text>
								</TextWrapper>
							</InformationBox>
						</CheckoutInformation>
					)}

					<CartWrapper>
						<Sticky
							enabled={size.width >= 768 ? true : false}
							top={120}
							innerZ={999}
						>
							<OrderInfo>
								<Title>
									<FormattedMessage
										id="cartTitle"
										defaultMessage="Your Order"
									/>
								</Title>

								<Scrollbars
									universal
									autoHide
									autoHeight
									autoHeightMax="390px"
									renderView={(props) => (
										<div
											{...props}
											style={{
												...props.style,
												marginLeft: isRtl ? props.style.marginRight : 0,
												marginRight: isRtl ? 0 : props.style.marginRight,
												paddingLeft: isRtl ? 15 : 0,
												paddingRight: isRtl ? 0 : 15,
											}}
										/>
									)}
								>
									<ItemsWrapper>
										{cartItemsCount > 0 ? (
											items.map((item) => (
												<OrderItem key={`cartItem-${item.id}`} product={item} />
											))
										) : (
											<>
												<NoProductImg>
													<NoCartBag />
												</NoProductImg>

												<NoProductMsg>
													<FormattedMessage
														id="noProductFound"
														defaultMessage="No products found"
													/>
												</NoProductMsg>
											</>
										)}
									</ItemsWrapper>
								</Scrollbars>

								<CalculationWrapper>
									<TextWrapper>
										<Text>
											<FormattedMessage
												id="subTotal"
												defaultMessage="Subtotal"
											/>
										</Text>
										<Text>
											{CURRENCY}
											{calculateSubTotalPrice()}
										</Text>
									</TextWrapper>

									<TextWrapper>
										<Text>
											<FormattedMessage
												id="intlOrderDetailsDelivery"
												defaultMessage="Delivery Fee"
											/>
										</Text>
										<Text>
											{CURRENCY}
											{delivery == true && addDeliveryCost ? "260" : "0"}
										</Text>
									</TextWrapper>

									<TextWrapper>
										<Text>
											<FormattedMessage
												id="discountText"
												defaultMessage="Discount"
											/>
										</Text>
										<Text>
											{CURRENCY}
											{calculateDiscount()}
										</Text>
									</TextWrapper>

									<TextWrapper style={{ marginTop: 20 }}>
										<Bold>
											<FormattedMessage id="totalText" defaultMessage="Total" />{" "}
											<Small>
												(
												<FormattedMessage
													id="vatText"
													defaultMessage="Incl. IVA"
												/>
												)
											</Small>
										</Bold>
										<Bold>
											{CURRENCY}
											{calculatePrice()}
										</Bold>
									</TextWrapper>

									<TextWrapper>
										<Text>
											<Bold>
												<Small>
													<div
														style={{
															display: "flex",
															alignItems: "center",
															color: "#77798C",
														}}
													>
														<FormattedMessage
															id="visaText"
															defaultMessage="Trabajamos con tarjetas VISA."
														/>{" "}
														<CardLogo>
															<img src={visa} style={{ marginLeft: "5px" }} />
														</CardLogo>
													</div>
												</Small>
											</Bold>
										</Text>
									</TextWrapper>
								</CalculationWrapper>
							</OrderInfo>
						</Sticky>
					</CartWrapper>
				</CheckoutContainer>
			</CheckoutWrapper>
		</form>
	);
};

export default CheckoutWithSidebar;
