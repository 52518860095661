import axios from "axios";

const generateDeviceFingerprint = (orderId) => {
    const sessionId = `visanetuy_px_42213_${orderId}`;
    
    // Add Cybersource script to head
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://h.online-metrix.net/fp/tags.js?org_id=9ozphlqx&session_id=${sessionId}`;
    document.head.appendChild(script);

    // Add noscript fallback
    const noscript = document.createElement('noscript');
    const iframe = document.createElement('iframe');
    iframe.style.width = '100px';
    iframe.style.height = '100px';
    iframe.style.border = '0';
    iframe.style.position = 'absolute';
    iframe.style.top = '-5000px';
    iframe.src = `https://h.online-metrix.net/fp/tags?org_id=9ozphlqx&session_id=${sessionId}`;
    noscript.appendChild(iframe);
    document.body.appendChild(noscript);

    return sessionId;
};

export const createPlexoSession = async (orderId) => {
    try {
        // Generate device fingerprint during session creation
        generateDeviceFingerprint(orderId);

        // Add custom form settings to request
        const requestBody = {
            metadata: orderId,
            webFormSettings: {
                UI: {
                    AutoDarkTheme: false,    // Disable auto dark theme to maintain consistent appearance
                    Colors: {
                        Background: "#ffffff",   // Keep white background for contrast
                        Primary: "#9abb51",    // Main color
                        Secondary: "#c5de8e"  // Secondary color
                    },
                    LogoURL: "https://norareyboutiqueonline.com.uy/static/media/logoBoutique.90b84c5f.png?text=Nora%20Rey%20Boutique%20Online",
                    Theme: "light"   
                }
            }
        };

        const response = await axios.post(
            "https://nora-rey-backend.herokuapp.com/api/orders/plexo-session", 
            requestBody
        );
        
        return response.data.sessionId;
    } catch (error) {
        console.error("Error creating Plexo session", error);
        throw error;
    }
};