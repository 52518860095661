import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import OrderReceivedWrapper, {
    OrderReceivedContainer,
    OrderInfo,
    OrderDetails,
    TotalAmount,
    BlockTitle,
    Text,
    InfoBlockWrapper,
    InfoBlock,
    ListItem,
    ListTitle,
    ListDes,
    CheckoutSubmit
} from "./order-received.style";
import { FormattedMessage } from "react-intl";
import { useCart } from "../../contexts/cart/use-cart";
import moment from "moment";
import ReactPixel from "react-facebook-pixel";
import { Button } from "../../components/button/button";
import axios from "axios";
import { SpinnerContainer, Spinner } from "../user-profile/order/order.style";
import { css } from "glamor";

const containerStyle = css({
    position: "relative",
});

const OrderReceived = () => {
    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState(null);
    const [confirmed, setConfirmed] = useState(false);
    const location = useLocation();
    const history = useHistory();
    const { clearCart } = useCart();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const orderId = searchParams.get('orderId');
        
        if (orderId) {
            const fetchOrder = async () => {
                try {
                    const response = await axios.get(`https://nora-rey-backend.herokuapp.com/api/orders/${orderId}`);
                    if (response.data.status === 1) {
                        history.push('/');
                        return;
                    }
                    setOrder(response.data);
                } catch (error) {
                    console.error('Error fetching order:', error);
                    toast.error('Error cargando los detalles de la orden');
                    history.push('/');
                }
            };
            fetchOrder();
        } else {
            history.push('/');
        }
    }, [location]);

    const handleConfirmPayment = async () => {
        if (!order?.paymentInstrument?.instrumentToken) {
            toast.error("El token de pago es inválido");
            return;
        }

        setLoading(true);
        try {
            const response = await axios.post('https://nora-rey-backend.herokuapp.com/api/orders/plexo-payment', {
                token: order.paymentInstrument.instrumentToken,
                orderId: order.id,
                amount: order.amount,
                email: order.email,
                stateData: order,
                deviceFingerprint: `visanetuy_px_42213_${order.id}`
            });

            if (response.data?.transactionId) {
                ReactPixel.track("Purchase", {
                    value: order.amount,
                    currency: "UYU",
                });
                setConfirmed(true);
                clearCart();
                setTimeout(() => {
                    history.push('/');
                }, 5000);
            }
        } catch (error) {
            console.error('Error en el pago:', error);
            toast.error(error.response?.data?.message || 'Error procesando el pago');
        } finally {
            setLoading(false);
        }
    };

    let itemCount = 0;
    if (order?.products?.length > 0) {
        order.products.forEach((i) => (itemCount += i.quantity));
    }

    if (!order) return (
        <SpinnerContainer>
            <Spinner></Spinner>
        </SpinnerContainer>
    );

    if (confirmed) {
        return (
            <OrderReceivedWrapper>
                <OrderReceivedContainer>
                    <OrderInfo>
                        <BlockTitle>
                            <FormattedMessage
                                id="orderReceivedText"
                                defaultMessage="¡Pedido Recibido!"
                            />
                        </BlockTitle>

                        <Text>
                            <FormattedMessage
                                id="orderReceivedSuccess"
                                defaultMessage="Gracias. Tu pedido ha sido confirmado correctamente."
                            />
                        </Text>

                        <Text style={{ marginTop: '10px', color: '#666' }}>
                            Serás redirigido al inicio en 5 segundos...
                        </Text>

                        <InfoBlockWrapper>
                            <InfoBlock>
                                <Text bold className="title">
                                    <FormattedMessage
                                        id="orderNumberText"
                                        defaultMessage="Número de Orden"
                                    />
                                </Text>
                                <Text>{order.id}</Text>
                            </InfoBlock>
                        </InfoBlockWrapper>
                    </OrderInfo>
                </OrderReceivedContainer>
            </OrderReceivedWrapper>
        );
    }

    return (
        <OrderReceivedWrapper>
            <ToastContainer className={containerStyle} />
            <OrderReceivedContainer>
                <OrderInfo>
                    <BlockTitle>
                        <FormattedMessage
                            id="orderPreviewText"
                            defaultMessage="Confirmación de Pedido"
                        />
                    </BlockTitle>

                    <Text style={{ marginBottom: '20px', color: '#666' }}>
                        <FormattedMessage
                            id="orderPreviewDescription"
                            defaultMessage="Por favor revisa los siguientes datos antes de confirmar tu pedido. Una vez confirmado, no se podrán realizar cambios."
                        />
                    </Text>

                    <InfoBlockWrapper>
                        <InfoBlock>
                            <Text bold className="title">
                                <FormattedMessage
                                    id="orderNumberText"
                                    defaultMessage="Número de Orden"
                                />
                            </Text>
                            <Text>{order.id}</Text>
                        </InfoBlock>

                        <InfoBlock>
                            <Text bold className="title">
                                <FormattedMessage id="orderDateText" defaultMessage="Fecha" />
                            </Text>
                            <Text>{moment(order.date).format("DD/MM/YY")}</Text>
                        </InfoBlock>

                        <InfoBlock>
                            <Text bold className="title">
                                <FormattedMessage id="totalText" defaultMessage="Total" />
                            </Text>
                            <Text>${order.amount}</Text>
                        </InfoBlock>

                        <InfoBlock>
                            <Text bold className="title">
                                <FormattedMessage
                                    id="paymentMethodText"
                                    defaultMessage="Método de pago"
                                />
                            </Text>
                            <Text>{order.paymentInstrument?.name || "Tarjeta de Crédito"}</Text>
                        </InfoBlock>
                    </InfoBlockWrapper>

                    <CheckoutSubmit>
                        <Button
                            type="button"
                            onClick={handleConfirmPayment}
                            disabled={loading}
                            size="big"
                            loading={loading}
                            style={{ width: "100%" }}
                        >
                            <FormattedMessage
                                id="confirmOrder"
                                defaultMessage="Confirmar Pedido"
                            />
                        </Button>
                    </CheckoutSubmit>
                </OrderInfo>

                <OrderDetails>
                    <BlockTitle>
                        <FormattedMessage
                            id="orderDetailsText"
                            defaultMessage="Detalles del Pedido"
                        />
                    </BlockTitle>

                    <ListItem>
                        <ListTitle>
                            <Text bold>
                                <FormattedMessage
                                    id="totalItemText"
                                    defaultMessage="Cantidad de Items"
                                />
                            </Text>
                        </ListTitle>
                        <ListDes>
                            <Text>{itemCount}</Text>
                        </ListDes>
                    </ListItem>

                    <ListItem>
                        <ListTitle>
                            <Text bold>
                                <FormattedMessage
                                    id="deliveryMethodText"
                                    defaultMessage="Método de Entrega"
                                />
                            </Text>
                        </ListTitle>
                        <ListDes>
                            <Text>
                                {order.deliveryMethod === 2
                                    ? "Delivery"
                                    : order.deliveryMethod === 3 
                                        ? "Retiro en tienda Pocitos" 
                                        : "Retiro en tienda Carrasco"}
                            </Text>
                        </ListDes>
                    </ListItem>

                    <ListItem>
                        <ListTitle>
                            <Text bold>
                                <FormattedMessage
                                    id="orderTimeText"
                                    defaultMessage="Fecha de Entrega"
                                />
                            </Text>
                        </ListTitle>
                        <ListDes>
                            <Text>{order.deliveryTime}</Text>
                        </ListDes>
                    </ListItem>

                    <ListItem>
                        <ListTitle>
                            <Text bold>
                                <FormattedMessage
                                    id="deliveryTimeText"
                                    defaultMessage="Horario"
                                />
                            </Text>
                        </ListTitle>
                        <ListDes>
                            <Text>
                                {order.deliveryMethod === 2
                                    ? "10.00 - 15.00"
                                    : "10.00 - 20.00"}
                            </Text>
                        </ListDes>
                    </ListItem>

                    {order.deliveryMethod === 2 && (
                        <ListItem>
                            <ListTitle>
                                <Text bold>
                                    <FormattedMessage
                                        id="deliveryLocationText"
                                        defaultMessage="Dirección de Entrega"
                                    />
                                </Text>
                            </ListTitle>
                            <ListDes>
                                <Text>{order.deliveryAddress}</Text>
                            </ListDes>
                        </ListItem>
                    )}
                </OrderDetails>

                <TotalAmount>
                    <BlockTitle>
                        <FormattedMessage
                            id="totalAmountText"
                            defaultMessage="Monto Total"
                        />
                    </BlockTitle>

                    <ListItem>
                        <ListTitle>
                            <Text bold>
                                <FormattedMessage id="subTotal" defaultMessage="Subtotal" />
                            </Text>
                        </ListTitle>
                        <ListDes>
                            <Text>${order.subtotal}</Text>
                        </ListDes>
                    </ListItem>

                    <ListItem>
                        <ListTitle>
                            <Text bold>
                                <FormattedMessage id="discount" defaultMessage="Descuento" />
                            </Text>
                        </ListTitle>
                        <ListDes>
                            <Text>${order.discount}</Text>
                        </ListDes>
                    </ListItem>

                    <ListItem>
                        <ListTitle>
                            <Text bold>
                                <FormattedMessage
                                    id="paymentMethodName"
                                    defaultMessage="Costo de Envío"
                                />
                            </Text>
                        </ListTitle>
                        <ListDes>
                            <Text>{order.deliveryFee ? `$${order.deliveryFee}` : 'No aplica'}</Text>
                        </ListDes>
                    </ListItem>

                    <ListItem>
                        <ListTitle>
                            <Text bold>
                                <FormattedMessage id="totalText" defaultMessage="Total" />
                            </Text>
                        </ListTitle>
                        <ListDes>
                            <Text>${order.amount}</Text>
                        </ListDes>
                    </ListItem>
                </TotalAmount>
            </OrderReceivedContainer>
        </OrderReceivedWrapper>
    );
};

export default OrderReceived;