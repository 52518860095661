import React, { useContext, useState, useEffect } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { Redirect, useHistory } from "react-router-dom";
import {
	DesktopView,
	MobileView,
	OrderBox,
	OrderListWrapper,
	OrderList,
	OrderDetailsWrapper,
	Title,
	ImageWrapper,
	ItemWrapper,
	ItemDetails,
	ItemName,
	ItemSize,
	ItemPrice,
	NoOrderFound,
} from "./order.style";
import { SpinnerContainer, Spinner } from "./order.style";
import { AuthContext } from "../../../contexts/auth/auth.context";

import { auth } from "../../../firebase";
import { ProfileContext } from "../../../contexts/profile/profile.context";

import OrderDetails from "./order-details/order-details";
import OrderCard from "./order-card/order-card";
import OrderCardMobile from "./order-card/order-card-mobile";
import useComponentSize from "../../../utils/useComponentSize";
import { FormattedMessage } from "react-intl";
import axios from "axios";
import useOrders from "../../../data/use-orders";
import ErrorMessage from "../../../components/error-message/error-message";

const progressData = ["Confirmado", "Entregado"];

const orderTableColumns = [
	{
		title: <FormattedMessage id="cartItems" defaultMessage="Items" />,
		dataIndex: "",
		key: "items",
		width: 250,
		ellipsis: true,
		render: (text, record) => {
			return (
				<ItemWrapper>
					<ImageWrapper>
						<img src={record.image} alt={record.title} />
					</ImageWrapper>

					<ItemDetails>
						<ItemName>{record.title}</ItemName>
						<ItemPrice>
							${record.salePrice ? record.salePrice : record.price}
						</ItemPrice>
					</ItemDetails>
				</ItemWrapper>
			);
		},
	},
	{
		title: (
			<FormattedMessage id="intlTableColTitle2" defaultMessage="Quantity" />
		),
		dataIndex: "quantity",
		key: "quantity",
		align: "center",
		width: 100,
	},
	{
		title: <FormattedMessage id="intlTableColTitle3" defaultMessage="Price" />,
		dataIndex: "",
		key: "price",
		align: "right",
		width: 100,
		render: (text, record) => {
			return (
				<p>
					$
					{record.salePrice
						? record.salePrice * record.quantity
						: record.price * record.quantity}{" "}
				</p>
			);
		},
	},
];

const OrdersContent = () => {
	const [targetRef, size] = useComponentSize();
	const orderListHeight = size.height - 79;
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	let history = useHistory();
	const {
		authState: { currentUser },
	} = React.useContext(AuthContext);

	useEffect(() => {
		const fetchData = async () => {
			try {
				auth.onAuthStateChanged(async function (user) {
					if (user) {
						const response = await axios.get(
							`https://nora-rey-backend.herokuapp.com/api/orders/from-user/${user?.uid}`,
							{
								headers: {
									accept: "application/json",
									"Accept-Language": "en-US,en;q=0.8",
								},
							}
						);
						setData(response.data.filter(o => !!o.transactionId));
						setLoading(false);
					} else {
						history.push('/');

					}
				});
			} catch (error) {
				// setError(error);
				setLoading(false);
			}
		};

		fetchData();
	}, []);

	const [selection, setSelection] = useState(null);

	useEffect(() => {
		if (data?.length) {
			setSelection(data[0]);
		}
	}, [data?.length]);

	if(!loading && (!data)) {
		return <Redirect to="/" />
	}

	// if (error) return <ErrorMessage message={error.message} />;

	return loading ? (
		<SpinnerContainer>
			<Spinner></Spinner>
		</SpinnerContainer>
	) : (
		<OrderBox>
			<DesktopView style={{ width: "100%" }}>
				<OrderListWrapper style={{ height: size.height }}>
					<Title style={{ padding: "0 20px" }}>
						<FormattedMessage
							id="intlOrderPageTitlePlural"
							defaultMessage="My Order"
						/>
					</Title>

					<Scrollbars
						universal
						autoHide
						autoHeight
						autoHeightMin={420}
						autoHeightMax={isNaN(orderListHeight) ? 500 : orderListHeight}
					>
						<OrderList>
							{data.length !== 0 ? (
								data.map((current) => (
									<OrderCard
										key={current.id}
										deliveryMethod={current.deliveryMethod}
										orderId={current.id}
										className={current.id === selection?.id ? "active" : ""}
										status={progressData[current.status - 1]}
										date={current.date}
										deliveryTime={current.deliveryTime}
										amount={current.amount}
										onClick={() => setSelection(current)}
									/>
								))
							) : (
								<NoOrderFound>
									<FormattedMessage
										id="intlNoOrderFound"
										defaultMessage="No order found"
									/>
								</NoOrderFound>
							)}
						</OrderList>
					</Scrollbars>
				</OrderListWrapper>

				<OrderDetailsWrapper ref={targetRef}>
					<Title style={{ padding: "0 20px" }}>
						<FormattedMessage
							id="orderDetailsText"
							defaultMessage="Order Details"
						/>
					</Title>
					{selection ? (
						<OrderDetails
							progressStatus={selection.status}
							progressData={progressData}
							address={selection.deliveryAddress}
							subtotal={selection.subtotal}
							deliveryMethod={selection.deliveryMethod}
							discount={selection.discount}
							deliveryFee={selection.deliveryFee}
							grandTotal={selection.amount}
							tableData={selection.products}
							columns={orderTableColumns}
						/>
					) : (
						<NoOrderFound>
							<FormattedMessage
								id="intlNoOrderFound"
								defaultMessage="No order found"
							/>
						</NoOrderFound>
					)}
				</OrderDetailsWrapper>
			</DesktopView>

			<MobileView>
				<OrderList>
					<OrderCardMobile
						orders={data}
						// className={order && order.id === active ? 'active' : ''}
						progressData={progressData}
						columns={orderTableColumns}
						onClick={setSelection}
					/>
				</OrderList>
			</MobileView>
		</OrderBox>
	);
};

export default OrdersContent;
